import * as React from "react"
import Layout from "../components/layout"
import { useState, useEffect } from "react"
import axios from "axios"

const TOS = () => {
  let [language, setLanguage] = useState("en")
  let [enValue, setEnValue] = useState("")
  let [vnValue, setVnValue] = useState("")

  useEffect(() => {
    axios
      .get(
        "https://texpress-api-arfum7vf6a-wl.a.run.app/api/settings?key=tos_vn"
      )
      .then(({ data }) => {
        setVnValue(data[0].value)
      })

    axios
      .get(
        "https://texpress-api-arfum7vf6a-wl.a.run.app/api/settings?key=tos_en"
      )
      .then(({ data }) => {
        setEnValue(data[0].value)
      })
  }, [])

  return (
    <Layout>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-10">
        <div className="w-full px-10 py-5 lg:w-full lg:px-0 lg:pl-10">
          <p className="text-5xl font-bold leading-relaxed w-4/5">
            Terms of Service
          </p>
          <small
            className={`${
              language === "en" ? "underline" : ""
            } cursor-pointer hover:text-blue-700`}
            onClick={() => setLanguage("en")}
          >
            English
          </small>
          <small className="mx-2">|</small>
          <small
            className={`${
              language === "vn" ? "underline" : ""
            } cursor-pointer hover:text-blue-700`}
            onClick={() => setLanguage("vn")}
          >
            Vietnamese
          </small>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 mt-4 px-10">
        {language === "en" ? (
          <div dangerouslySetInnerHTML={{ __html: enValue }}></div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: vnValue }}></div>
        )}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 px-10">
        <h5>
          No mobile information will be shared with third parties/affiliates for
          marketing/promotional purposes. All the above categories exclude text
          messaging originator opt-in data and consent; this information will
          not be shared with any third parties.
        </h5>
      </div>
    </Layout>
  )
}

export default TOS
